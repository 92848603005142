export const data = [
  {
    id: 1,
    imageUrl: "Images/yes.avif",
    imageAlt: "YES",
    title: "YES",
    languages: "Next.js, Typescript, Bootstrap",
    externalLink: "https://yes-next.vercel.app/",
  },
  {
    id: 2,
    imageUrl: "Images/palmJobs.avif",
    imageAlt: "Palm Jobs",
    title: "Palm Jobs",
    languages: "Next.js, Tailwind Css, TypeScript, AppWrite",
    externalLink: "https://palmjobs.et/",
  },
  {
    id: 3,
    imageUrl: "Images/dfsakofada.avif",
    imageAlt: "Sample portfolio",
    title: "DFS Ethiopia Hub",
    languages: "Next.js, Tailwind Css, Strapi",
    externalLink: "https://akofada.shega.co",
  },
  {
    id: 4,
    imageUrl: "Images/nylos.avif",
    imageAlt: "Nylos",
    title: "Nylos",
    languages: "React.js, Tailwind Css",
    externalLink: "https://nylos.et/",
  },
  {
    id: 5,
    imageUrl: "Images/placetobe.avif",
    imageAlt: "Place to be Ethiopia",
    title: "Place to be Ethiopia",
    languages: "React.js, Material UI",
    externalLink: "https://www.placetobeethiopia.com/",
  },
  {
    id: 6,
    imageUrl: "Images/nylos-v1.avif",
    imageAlt: "Nylos",
    title: "Nylos version 1.0.0",
    languages: "React.js, Tailwind Css",
    externalLink: "https://nylos.vercel.app/",
  },
  /* {
    id: 6,
    imageUrl: "Images/arcBack.avif",
    imageAlt: "Arc Back",
    title: "Arc Back",
    languages: "Next.js, Typescript, Bootstrap",
    externalLink: "https://arc-back.vercel.app/",
  }, */
];
